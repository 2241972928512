import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

import { Header } from '../../shared/src/components';
import LoadingScreen from '../../shared/src/components/LoadingScreen/LoadingScreen';
import { Container } from '@digitools/honest-react';
import { getEnvVariable } from '../../shared/src/utils/EnvUtils';
import Auth0Provider from '../../shared/src/providers/Auth0Provider';
import { ThemeProvider } from '@digitools/honest-react/dist/lib/theming/styled-components';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { useLocation } from 'react-router-dom';

const LandingPage = lazy(() => import('./components/LandingPage'));
const UnauthorizedPage = lazy(() => import('./components/Unauthorized'));

const auth0RedirectUri = getEnvVariable('auth0RedirectUri');

const ProtectedRouter = () => (
  <Auth0Provider redirectUri={auth0RedirectUri} redirectPathname={'/callback'} successFallbackUrl={'/'}>
    <Route exact={true} path={'/'} component={LandingPage} />
  </Auth0Provider>
);

const PublicRouter = () => <Route path={'/public/unauthorized'} component={UnauthorizedPage} />;

const TestPrint = () => {

  const location = useLocation();
  console.log( location.pathname );
  return <></>
}

const App = () => (
  <ThemeProvider theme={themes.default}>
    <>
      <Header />
      <Router>
        <TestPrint />
        <Suspense fallback={<LoadingScreen />}>
          <Container className={'mt-5'}>
            <Switch>
              <Route path={'/public'} component={PublicRouter} />
              <Route path={'/'} component={ProtectedRouter} />
            </Switch>
          </Container>
        </Suspense>
      </Router>
    </>
  </ThemeProvider>
);

// Note: hot wont do anything in production mode
export default hot(App);
